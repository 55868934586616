import { useRef, useState } from 'react';
import styled from 'styled-components';
import providersImg from 'assets/providers.webp';

const Container = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;
  width: 100%;
  color: #201e1e;
`;
const Title = styled.h2`
  margin-bottom: 32px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.334;
`;
const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Question = styled.div`
  padding: 0 16px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
`;

const QuestionHeader = styled.div`
  font-size: 1rem;
  margin: 20px 0;
  color: #201e1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  & h3 {
    font-size: 1rem;
    font-weight: 400;
  }

  & div {
    width: 24px;
    height: 24px;
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    transition: transform 0.3s;
    flex-shrink: 0;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const QuestionText = styled.div`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.43;
  letter-spacing: 0.1px;

  overflow: hidden;
  transition: height 0.3s;
  height: ${({ isActive, h }) => (isActive ? `${h}px` : 0)};
  & div {
    padding: 8px 0 16px 0;
  }
`;

const questionsData = [
  {
    title: 'Why do you need my credit card for a trial?',
    text: (
      <>
        We ask for your credit card to allow your membership to continue after your trial, should you choose not to
        cancel.
        <br />
        This also allows us to reduce fraud and prevent multiple trials for one person. This helps us deliver better
        service for all the customers.
        <br />
        Remember that we won't bill you anything during your trial and that you can cancel at any moment before your
        trial ends.
      </>
    ),
    isActive: false,
  },
  {
    title: 'How do I cancel my membership?',
    text: <>You can cancel your GuardPRO membership at any time from the membership section in your Dashboard.</>,
    isActive: false,
  },
  {
    title: 'Is my membership valid on multiple devices?',
    text: (
      <>
        Of course!
        <br />A single GuardPRO membership can be activated on up to 5 devices. You can also invite 4 additional family
        members to your membership for free.
      </>
    ),
    isActive: false,
  },
  {
    title: 'What about your privacy policy?',
    text: (
      <>
        We will never share or sell your personal information. Payment information is not stored in our database and is
        passed directly to the card issuer (100% PCI compliance). Here you can find the full{' '}
        <a
          href="https://guardproapp.com/privacy/"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            color: '#2591f1',
          }}
        >
          Privacy Policy
        </a>
      </>
    ),
    isActive: false,
  },
  {
    title: 'What forms of payment do you accept?',
    text: (
      <>
        We currently accept these major payment providers
        <br />
        <img
          src={providersImg}
          alt="provides"
          style={{
            width: '100%',
          }}
        />
      </>
    ),
    isActive: false,
  },
];

const Faq = () => {
  const [data, setData] = useState(questionsData);

  const refs = useRef([]);

  const hadleActive = (e, i, j) => {
    if (e.isActive && i === j) {
      return false;
    } else {
      if (i === j) {
        return true;
      } else {
        return e.isActive;
      }
    }
  };
  // console.log(data);
  return (
    <Container>
      <Title>Frequently asked questions</Title>
      <Questions>
        {data.map((e, i) => (
          <Question
            key={i}
            onClick={() => {
              setData((prev) =>
                prev.map((e, j) => ({
                  ...e,
                  isActive: hadleActive(e, i, j),
                })),
              );
            }}
          >
            <QuestionHeader isActive={e.isActive}>
              <h3>{e.title}</h3>
              <div>
                <Arrow />
              </div>
            </QuestionHeader>
            <QuestionText isActive={e.isActive} h={refs.current[i]?.getBoundingClientRect()?.height}>
              <div ref={(el) => (refs.current[i] = el)}>{e.text}</div>
            </QuestionText>
          </Question>
        ))}
      </Questions>
      <p
        style={{
          marginTop: '40px',
          marginBottom: '20px',
          color: '#757575',
          textAlign: 'center',
        }}
      >
        For technical or product related questions&nbsp; email us at{' '}
        <a
          href="mailto:info@guardproapp.com"
          style={{
            color: '#757575',
          }}
        >
          info@guardproapp.com
        </a>
      </p>
    </Container>
  );
};

export default Faq;

const Arrow = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
  </svg>
);
