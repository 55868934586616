export const STEP_NAMES = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  PROGRESS: 'PROGRESS',
  LEAKS_LIST: 'LEAKS_LIST',
  QUIZ: 'QUIZ',
  GO_TO_PAY: 'GO_TO_PAY',
  PAYMENT: 'PAYMENT',
  SUCCESS: 'SUCCESS',
};

export const stepInOrder = [
  STEP_NAMES.ENTER_EMAIL,
  STEP_NAMES.PROGRESS,
  STEP_NAMES.LEAKS_LIST,
  STEP_NAMES.QUIZ,
  STEP_NAMES.GO_TO_PAY,
  STEP_NAMES.PAYMENT,
  STEP_NAMES.SUCCESS,
];

export const getStepNameByIndex = (index) => {
  return stepInOrder[index];
};
