import styled from 'styled-components';
import Ic1 from 'assets/ic1.png';
import Ic2 from 'assets/ic2.png';
import Ic3 from 'assets/ic3.png';

const Container = styled.div`
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #201e1e;
  margin-bottom: 28px;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Card = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  display: flex;
  gap: 16px;
  align-items: center;

  & img {
    border-radius: 8px;
    width: 60px;
    height: 60px;
  }
  & p {
    line-height: 20px;
    font-size: 0.875rem;
    letter-spacing: normal;
  }
`;

const data = [
  {
    icon: Ic1,
    text: '7-Day trial.',
  },
  {
    icon: Ic2,
    text: 'No commitments, cancel online anytime.',
  },
  {
    icon: Ic3,
    text: "We'll email you a reminder 2 days before your trial ends.",
  },
];

const Benefits = () => {
  return (
    <Container>
      <Cards>
        {data.map((e, i) => (
          <Card key={i}>
            <img src={e.icon} alt="icon" />
            <p>{e.text}</p>
          </Card>
        ))}
      </Cards>
    </Container>
  );
};

export default Benefits;
