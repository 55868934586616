import styled from 'styled-components';

const Container = styled.div`
  background-color: rgb(39, 174, 96);
  color: rgb(255, 255, 255);
  font-weight: 500;
  width: 100%;

  padding: 20px;
  & p {
    font-size: 1rem !important;
    text-align: center;
  }
`;

const TopBar = () => {
  return (
    <Container>
      <p>Pay $0 Now. Enjoy a 7-day trial</p>
    </Container>
  );
};

export default TopBar;
