import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { eventParams, sendPostBack } from 'helpers';
import { Container, Title, Text, ButtonBottomPositioned } from 'components/ui';
import Star from 'assets/star';
import { isUrlParam } from 'utils';

const ContainerWithHeight = styled(Container)`
  min-height: 550px;
`;

const TextPositioned = styled(Text)`
  margin-bottom: 42px;
`;

const TextRelative = styled(Text)`
  position: relative;
  padding-left: 22px;
  color: #172335;
  font-size: 0.777rem;
  margin-bottom: 24px;
`;

const LeaksContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  position: relative;

  &::after {
    content: '';
    position: sticky;
    top: calc(100% - 150px);
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;

    margin-top: auto;
    background-image: linear-gradient(to top, #f5f6ff 0%, transparent 100%);
    opacity: 0;
    height: 0;
    transition: opacity, 0.5s;
    pointer-events: none;
  }
  &.leaks-veil-visible::after {
    min-height: 150px;
    opacity: 1;
  }
  @media (min-width: 900px) {
    overflow-y: scroll;
    margin-bottom: 16px;
  }
`;

const ListItemContainerLeaks = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 16px 8px 16px 13px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-shrink: 0;
  gap: 8px;

  & img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
  }

  & div:first-of-type {
    & h3 {
      margin-bottom: 3px;
      font-size: 0.777rem;
      line-height: 1.16rem;
      font-weight: 700;
      color: #172335;
    }
    & p {
      font-size: 0.666rem;
      line-height: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 270px;
    }
    @media (max-width: 440px) {
      & p {
        max-width: 200px;
      }
    }
    @media (max-width: 376px) {
      & p {
        max-width: 180px;
      }
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 100000000;
`;

const OverlayContent = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius: 6px;
  background: #fff;
  padding: 22px 20px;
  display: flex;
  flex-direction: column;
  max-height: 85%;
  overflow-y: auto;
`;

const OverlayHeader = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 30px;
  & img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    object-position: center;
  }
  & p {
    font-weight: 600;
    font-size: 1rem;
  }
`;
const OverlayTitle = styled.span`
  font-size: 1.11rem;
  font-weight: 700;
  color: #172335;
  word-break: break-word;
`;

const OverlayText = styled.div`
  margin-bottom: 18px;

  & h3 {
    font-size: 0.9rem;
    color: #172335;
    font-weight: 600;
    margin-bottom: 4px;
  }
  & p {
    font-size: 0.888rem;
    line-height: 1.333rem;
  }
`;

const OverlayClose = styled.div`
  font-size: 0.777rem;
  color: #2f79e8;
  cursor: pointer;
  align-self: center;
  border: 1px solid #2f79e8;
  border-radius: 4px;
  padding: 4px 10px;
  margin-top: 30px;
`;

const StarContainer = styled.span`
  position: absolute;
  left: 0;
  top: 2px;
`;

const noLeaksData = [
  {
    Name: 'Facebook',
    Title: 'Facebook',
    Domain: 'facebook.com',
    BreachDate: '2019-08-01',
    AddedDate: '2021-04-04T03:20:45Z',
    ModifiedDate: '2021-04-06T09:09:21Z',
    PwnCount: 509458528,
    Description:
      "In April 2021, a large data set of over 500 million Facebook users was made freely available for download. Encompassing approximately 20% of Facebook's subscribers, the data was allegedly obtained by exploiting a vulnerability Facebook advises they rectified in August 2019. The primary value of the data is the association of phone numbers to identities; whilst each record included phone, only 2.5 million contained an email address. Most records contained names and genders with many also including dates of birth, location, relationship status and employer.",
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/Facebook.png',
    DataClasses: [
      'Dates of birth',
      'Email addresses',
      'Employers',
      'Genders',
      'Geographic locations',
      'Names',
      'Phone numbers',
      'Relationship statuses',
    ],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
  {
    Name: 'Yahoo',
    Title: 'Yahoo',
    Domain: 'yahoo.com',
    BreachDate: '2012-07-11',
    AddedDate: '2013-12-04T00:00:00Z',
    ModifiedDate: '2013-12-04T00:00:00Z',
    PwnCount: 453427,
    Description:
      'In July 2012, Yahoo! had their online publishing service &quot;Voices&quot; compromised via a SQL injection attack. The breach resulted in the disclosure of nearly half a million usernames and passwords stored in plain text. The breach showed that of the compromised accounts, a staggering 59% of people who also had accounts in the Sony breach reused their passwords across both services.',
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/Yahoo.png',
    DataClasses: ['Email addresses', 'Passwords'],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
  {
    Name: 'LinkedInScrape',
    Title: 'LinkedIn Scraped Data (2021)',
    Domain: 'linkedin.com',
    BreachDate: '2021-04-08',
    AddedDate: '2021-10-02T21:39:21Z',
    ModifiedDate: '2023-11-07T06:51:33Z',
    PwnCount: 125698496,
    Description:
      'During the first half of 2021, LinkedIn was targeted by attackers who scraped data from hundreds of millions of public profiles and later sold them online. Whilst the scraping did not constitute a data breach nor did it access any personal data not intended to be publicly accessible, the data was still monetised and later broadly circulated in hacking circles. The scraped data contains approximately 400M records with 125M unique email addresses, as well as names, geographic locations, genders and job titles. LinkedIn specifically addresses the incident in their post on An update on report of scraped data.',
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/LinkedIn.png',
    DataClasses: [
      'Education levels',
      'Email addresses',
      'Genders',
      'Geographic locations',
      'Job titles',
      'Names',
      'Social media profiles',
    ],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
];

const LEAKS_ITEM_MARGIN = 12;

const isNeedVeil = (leaksListRef, leaksData) => {
  if (!leaksListRef.current) return;
  const listItem = leaksListRef.current.querySelector('div:first-child');

  const itemsListHeight = leaksData.length * (listItem.offsetHeight + LEAKS_ITEM_MARGIN) - LEAKS_ITEM_MARGIN;

  if (itemsListHeight - (leaksListRef.current.offsetHeight + leaksListRef.current.scrollTop) < 70) {
    return false;
  }
  return true;
};

const LeaksList = ({ leaksData, incrementStep }) => {
  const [isDetails, setIsDetails] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const leaksListRef = useRef(null);

  const [isVeil, setIsVeil] = useState(isNeedVeil(leaksListRef, leaksData));

  const overlayData = leaksData ? leaksData[currentId] : noLeaksData[currentId];

  const clickId = isUrlParam('clickId');

  useEffect(() => {
    setIsVeil(isNeedVeil(leaksListRef, leaksData));
  }, [leaksData]);

  useEffect(() => {
    if (clickId) {
      sendPostBack(eventParams.OPEN_SCAN, clickId);
    }
  }, []);

  const handleListScroll = (e) => {
    setIsVeil(isNeedVeil(leaksListRef, leaksData));
  };

  const renderDetails = () => {
    return (
      <Overlay
        onClick={() => {
          setIsDetails(false);
        }}
      >
        <OverlayContent
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <OverlayHeader>
            <img src={overlayData.LogoPath} alt="logo" />
            <OverlayTitle>{overlayData.Title}</OverlayTitle>
          </OverlayHeader>
          <OverlayText>
            <h3>Affected Users</h3>
            <p>{overlayData.PwnCount}</p>
          </OverlayText>

          <OverlayText>
            <h3>Compromised Data</h3>
            <p dangerouslySetInnerHTML={{ __html: overlayData.Description }}></p>
          </OverlayText>
          <OverlayClose
            onClick={() => {
              setIsDetails(false);
            }}
          >
            Close
          </OverlayClose>
        </OverlayContent>
      </Overlay>
    );
  };

  return (
    <ContainerWithHeight>
      {isDetails && renderDetails()}
      {leaksData ? (
        <>
          <Title>Thank you for choosing GuardPRO protection!</Title>
          <TextPositioned>We will send you a notification if something happens to your email.</TextPositioned>
          <TextRelative>
            <StarContainer>
              <Star />
            </StarContainer>
            We recommend that you change the password you used on the following sites:
          </TextRelative>
          <LeaksContainer ref={leaksListRef} onScroll={handleListScroll} className={isVeil ? 'leaks-veil-visible' : ''}>
            {leaksData.map((e, i) => (
              <ListItemContainerLeaks
                key={i}
                onClick={() => {
                  setCurrentId(i);
                  setIsDetails(true);
                }}
              >
                <img src={e.LogoPath} alt="logo" />
                <div>
                  <h3>{e.Title}</h3>
                  <p>{e.DataClasses.map((el, i) => (i < e.DataClasses.length - 1 ? `${el}, ` : `${el} `))}</p>
                </div>
              </ListItemContainerLeaks>
            ))}
          </LeaksContainer>
        </>
      ) : (
        <>
          <Title>No Data Leaks Were Found</Title>
          <TextPositioned>
            We searched for your personal info in{' '}
            <span
              style={{
                color: '#172335',
              }}
            >
              32,284{' '}
            </span>
            data breaches
          </TextPositioned>

          <LeaksContainer>
            <Text
              style={{
                color: '#172335',
              }}
            >
              Leaks we've found for others like you:
            </Text>
            {noLeaksData.map((e, i) => (
              <ListItemContainerLeaks
                key={i}
                onClick={() => {
                  setCurrentId(i);
                  setIsDetails(true);
                }}
              >
                <img src={e.LogoPath} alt="logo" />
                <div>
                  <h3>{e.Title}</h3>
                  <p>{e.Description}</p>
                </div>
              </ListItemContainerLeaks>
            ))}
          </LeaksContainer>
        </>
      )}
      <ButtonBottomPositioned
        onClick={() => {
          incrementStep();
        }}
      >
        Protect data
      </ButtonBottomPositioned>
    </ContainerWithHeight>
  );
};

export default LeaksList;
