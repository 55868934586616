import styled from 'styled-components';
import Guard from 'assets/guard-main';
import { Title, Text, Button, GuardBackground } from 'components/ui';

const GuardWrapper = styled.div`
  align-self: center;
`;

const ProtectData = ({ incrementStep }) => {
  const handleClick = () => {
    incrementStep();
  };

  return (
    <GuardBackground
      style={{
        gap: '20px',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Title>No more data leaks: Guard PRO is your reliable shield</Title>
        <Text>
          Rest assured that your personal information is confidential. Guard Pro uses the most advanced technologies to
          protect your data.
        </Text>
      </div>
      <GuardWrapper>
        <Guard />
      </GuardWrapper>

      <Button onClick={handleClick}>Protect My Privacy</Button>
    </GuardBackground>
  );
};
export default ProtectData;
