import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const TimerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 1.4rem;
  font-weight: 700;

  font-family: monospace;
`;

const TimerItem = styled.span`
  width: 40px;
  display: inline-block;
  padding: 10px 4px;
  border-radius: 6px;
  text-align: center;
  background-color: #fff;
`;

const getTime = (ms) => {
  let min = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  let sec = Math.floor((ms % (1000 * 60)) / 1000);

  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;
  return {
    minutes: String(min).split(''),
    seconds: String(sec).split(''),
  };
};

const getStopTime = (delayMinutes) => {
  const startTime = new Date();
  const stopTime = startTime.setMinutes(startTime.getMinutes() + delayMinutes);
  return stopTime;
};

const Timer = ({ delayMinutes }) => {
  const stopTime = useRef(getStopTime(delayMinutes));
  const [timeMs, setTime] = useState(delayMinutes * 1000 * 60);

  useEffect(() => {
    if (timeMs <= 0) {
      setTime(0);
      return;
    }
    const intervalId = setInterval(() => {
      if (timeMs <= 0) {
        clearInterval(intervalId);

        return;
      }
      setTime(stopTime.current - new Date().getTime());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeMs]);

  const timeToRender = getTime(timeMs);
  return (
    <TimerStyled>
      {timeToRender.minutes.map((item, i) => {
        return <TimerItem key={i}>{item}</TimerItem>;
      })}
      :
      {timeToRender.seconds.map((item, i) => {
        return <TimerItem key={i}>{item}</TimerItem>;
      })}
    </TimerStyled>
  );
};

export default Timer;
