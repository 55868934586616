import { useState, useEffect } from 'react';
import styled from 'styled-components';
import questionnaire from './questionnaire';

import { Container, Title, ButtonBottomPositioned } from 'components/ui';
import ArrowLeft from 'assets/arrow-left';

const TitlePositioned = styled(Title)`
  margin-bottom: 32px;
`;

const Option = styled.label`
  display: block;
  width: 100%;
  padding: 20px 56px 20px 20px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  font-size: 0.777rem;
  line-height: 1.166rem;
  color: #172335;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
`;

const OptionsList = styled.div`
  width: 100%;
  margin-bottom: 32px;
  > label {
    margin-bottom: 12px;
  }
`;

const Radio = styled.input`
  position: absolute;
  right: 22px;
  top: 50%;
  opacity: 0;
  &:focus {
    outline: none;
  }
  & + span {
    display: block;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ced1da;
  }
  &:checked + span {
    border: 5px solid #375dfb;
  }
`;
const ArrowStyled = styled.button`
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 0;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const initialState = questionnaire.reduce((state, item) => {
  state[item.id] = '';
  return state;
}, {});

const Quiz = ({ incrementStep }) => {
  const [response, setResponse] = useState(initialState);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const item = questionnaire[currentQuestion];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestion]);

  const onChange = (optionId) => {
    setResponse((response) => ({
      ...response,
      [item.id]: optionId,
    }));
  };

  const handleClick = () => {
    if (currentQuestion === questionnaire.length - 1) {
      incrementStep();
      //save responses?
    }
    if (response[item.id]) {
      setCurrentQuestion((current) => current + 1);
    }
  };

  const handleQuizGoBack = () => {
    if (currentQuestion === 0) return;
    setCurrentQuestion((current) => current - 1);
  };

  return (
    <Container>
      <ArrowStyled onClick={handleQuizGoBack} style={{ opacity: currentQuestion === 0 ? '0.3' : '0.7' }}>
        <ArrowLeft />
      </ArrowStyled>

      <TitlePositioned>{item.title}</TitlePositioned>
      <OptionsList>
        {item.options.map((option) => (
          <Option key={option.id}>
            {option.text}
            <Radio
              type="radio"
              name={item.id}
              checked={response[item.id] === option.id}
              onChange={() => onChange(option.id)}
            />
            <span />
          </Option>
        ))}
      </OptionsList>
      <ButtonBottomPositioned
        style={
          !response[item.id]
            ? {
                backgroundColor: '#accaf6',
              }
            : {}
        }
        onClick={() => handleClick()}
        disabled={!response[item.id]}
      >
        {questionnaire.length - 1 === currentQuestion ? 'Last step' : 'Next'}
      </ButtonBottomPositioned>
    </Container>
  );
};
export default Quiz;
