import styled from 'styled-components';

import { useEffect, useRef, useState } from 'react';
import { Radar } from 'assets/radar';
import { Title, Text, Button } from 'components/ui';

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  padding: 24px;
`;

const Logo = styled.div`
  border-radius: 12px;
  background: #ebf4ff;
  padding: 12px;
  margin-bottom: 24px;
`;

const ProgressBar = styled.div`
  border: 1px solid #2f79e8;
  border-radius: 6px;
  width: 100%;
  margin: 8px 0;
  position: relative;
`;

const Indicator = styled.div`
  height: 6px;
  background: #2f79e8;
  border-radius: 1px;
  width: ${({ w }) => (w ? `${w}%` : 0)};
  transition: width 0.5s;
  position: relative;
  z-index: 2;
`;

const Progress = ({ email, setLeaksData, incrementStep }) => {
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);
  const interval = useRef();

  useEffect(() => {
    const getDataFormApi = async () => {
      const url = `https://haveibeenpwned.com/api/v3/breachedaccount/${email || ''}?truncateResponse=false`;

      try {
        const response = await fetch(
          `https://driver-updater.herokuapp.com/api/v1/front/proxyquery/?service_for_my_benefits=haveibeenpwned.com&url=${btoa(
            url,
          )}`,
        );
        if (response.ok) {
          const data = await response.json();
          setLeaksData(data);
        } else {
          setLeaksData(null);
        }
      } catch (error) {
        setProgress(100);
        setTimeout(() => {
          incrementStep();
        }, 500);
        setLeaksData(null);
        clearInterval(interval.current);
      }
    };

    getDataFormApi();

    interval.current = setInterval(() => {
      if (progressRef.current >= 100) {
        setProgress(100);
        clearInterval(interval.current);
        return setTimeout(() => {
          incrementStep();
        }, 500);
      }
      progressRef.current = progressRef.current + 20;
      setProgress(progressRef.current);
    }, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <>
      <Container>
        <Logo>
          <Radar />
        </Logo>
        <Title>{progress >= 100 ? 'Scan Complete' : 'Scanning for Data Leaks...'}</Title>
        <ProgressBar>
          <Indicator w={progress} />
        </ProgressBar>
        <Text style={{ textAlign: 'center' }}>
          Scan status for <code style={{ color: '#172335' }}>{email}</code>
        </Text>
      </Container>
    </>
  );
};

export default Progress;
