import { useEffect, useRef } from 'react';
import Item from '../item';

const { default: styled } = require('styled-components');

const Container = styled.div`
  width: calc(100% - 80px);
  overflow: hidden;

  .--carousel-go-left {
    // animation: carousel-anim-left 0.5s linear 0s 1 both;
    transform: translateX(-66.6%);
    transition: transform 0.4s;
  }
  .--carousel-go-right {
    // animation: carousel-anim-right 0.5s linear 0s 1 both;
    transform: translateX(0);
    transition: transform 0.4s;
  }

  @keyframes carousel-anim-left {
    0% {
      transform: translateX(-33.3%);
    }
    100% {
      transform: translateX(-66.6%);
    }
  }
  @keyframes carousel-anim-right {
    0% {
      transform: translateX(-33.3%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const Content = styled.div`
  display: flex;
  width: 300%;
  transform: translateX(-33.3%);
`;

const handleFocusedData = (currentId, data) => {
  if (currentId === 0) {
    const prev = data[data.length - 1];
    const focused = data[currentId];
    const forward = data[currentId + 1];
    return [prev, focused, forward];
  } else if (currentId >= data.length - 1) {
    const prev = data[currentId - 1];
    const focused = data[currentId];
    const forward = data[0];
    return [prev, focused, forward];
  } else {
    const prev = data[currentId - 1];
    const focused = data[currentId];
    const forward = data[currentId + 1];
    return [prev, focused, forward];
  }
};

const Carousel = ({ currentReviewId, reviews, isAnimStarted, setIsAnimStarted, direction, setCurrentReviewId }) => {
  useEffect(() => {
    if (isAnimStarted) {
      if (direction === 'left') {
        ref.current.classList.add('--carousel-go-left');
        setTimeout(() => {
          setIsAnimStarted(false);
          ref.current.classList.remove('--carousel-go-left');
          if (currentReviewId >= reviews.length - 1) {
            setCurrentReviewId(0);
          } else {
            setCurrentReviewId((p) => p + 1);
          }
        }, 501);
      } else {
        ref.current.classList.add('--carousel-go-right');

        setTimeout(() => {
          setIsAnimStarted(false);
          ref.current.classList.remove('--carousel-go-right');
          if (currentReviewId <= 0) {
            setCurrentReviewId(reviews.length - 1);
          } else {
            setCurrentReviewId((p) => p - 1);
          }
        }, 501);
      }
    }
  }, [isAnimStarted]);

  const ref = useRef();

  return (
    <Container>
      <Content ref={ref}>
        {handleFocusedData(currentReviewId, reviews).map((e, i) => (
          <Item review={e} />
        ))}
      </Content>
    </Container>
  );
};

export default Carousel;
