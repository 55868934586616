import { useState } from 'react';
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Snackbar } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Loader from 'components/loader';
import { Container, Title, TextPositioned, Button } from 'components/ui';
import { Radar } from 'assets/radar';
import { eventParams, sendPostBack } from 'helpers';
import { isUrlParam } from 'utils';
import GoogleLoginBlock from './googleLogin';

export const Logo = styled.div`
  border-radius: 12px;
  background: #ebf4ff;
  padding: 12px;
  margin-bottom: 32px;
  align-self: center;
`;

export const EmailInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const CustomizedTextInput = styled(TextField)`
  .MuiOutlinedInput-root {
    font-family: inherit;
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #accaf6;
    }
  }
  .MuiInputBase-input {
    padding: 20px 16px;
    font-size: 0.9rem;
  }
  .MuiInputBase-input::placeholder {
    color: #8b919a;
    opacity: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #accaf6;
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #accaf6;
  }
`;

export const SnackBtn = styled.div`
  margin-right: 12px;
`;

export const Law = styled.div`
  color: #8b919a;
  font-size: 0.67rem;
  line-height: 1rem;
  margin-top: auto;
  & a {
    color: inherit;
    cursor: pointer;
  }
`;
const ButtonPositioned = styled(Button)`
  margin-bottom: 16px;
`;

const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EnterEmail = ({ email, setEmail, incrementStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [isOpenSnack, setIsOpenSnack] = useState(false);

  const clickId = isUrlParam('clickId');

  const handleValue = (emailValue) => {
    setIsEmailErr(false);
    setEmail(emailValue);
  };

  return (
    <>
      {isLoading && <Loader text="Processing..." isFixed={true} />}

      <Container>
        <Snackbar
          open={isOpenSnack}
          style={{
            top: 8,
            right: 24,
            bottom: 'auto',
          }}
        >
          <Alert
            onClose={() => {
              setIsOpenSnack(false);
            }}
            severity="error"
            className="snackbar"
            icon={<ErrorIcon />}
            action={
              <SnackBtn
                onClick={() => {
                  setIsOpenSnack(false);
                }}
              >
                Dismiss
              </SnackBtn>
            }
          >
            Email address is invalid. Please correct and try again.
          </Alert>
        </Snackbar>
        <Logo>
          <Radar />
        </Logo>
        <Title>
          What email address do you <br /> want to scan?
        </Title>
        <TextPositioned>
          Run a free scan and check for personal data leaks. Type in your email to register and start your scan.
        </TextPositioned>

        <GoogleLoginBlock
          onSuccess={(emailValue) => {
            if (!emailValue) {
              console.error('Google login callback error');
              return;
            }
            setEmail(emailValue);
          }}
        ></GoogleLoginBlock>
        <EmailInput>
          <CustomizedTextInput
            placeholder="example@gmail.com"
            variant="outlined"
            size="normal"
            type="email"
            value={email}
            onChange={(e) => handleValue(e.target.value)}
            error={isEmailErr}
            helperText={isEmailErr && 'incorrect email'}
            InputProps={{
              endAdornment: isEmailErr && (
                <InputAdornment position="start">
                  <ErrorIcon style={{ color: '#E57373' }} />
                </InputAdornment>
              ),
            }}
          />
        </EmailInput>
        <ButtonPositioned
          onClick={() => {
            if (email && email.length > 0 && emailReg.test(email)) {
              setIsLoading(true);
              if (clickId) {
                sendPostBack(eventParams.EMAIL_ENTERED, clickId);
              }

              setTimeout(() => {
                setIsEmailErr(false);
                setIsLoading(false);
                return incrementStep();
              }, 500);

              return;
            } else {
              setIsEmailErr(true);
              setIsOpenSnack(true);
              return;
            }
          }}
        >
          Start Free Scan
        </ButtonPositioned>
        <Law>
          By signing up, you agree to our{' '}
          <a target="_blank" rel="noreferrer" href="https://guardproapp.com/terms/">
            Terms of Service
          </a>{' '}
          and{' '}
          <a target="_blank" rel="noreferrer" href="https://guardproapp.com/privacy/">
            Privacy Policy
          </a>
          . You also agree to receive product-related marketing emails from GuardPRO, which you can unsubscribe from at
          any time.
        </Law>
      </Container>
    </>
  );
};

export default EnterEmail;
