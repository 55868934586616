import { baseUrl } from 'constants';

export const shouldModifyPage = (name) => {
  return isUrlParam(name.toLowerCase());
};

export const getUrlParamByName = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

export const isUrlParam = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.has(name);
};

/**
 *
 * @param { String<constants/URL_PATHS> } path
 * @param { string[] } params
 * @returns string
 */
export const getUrl = (path, params) => {
  if (params) {
    const stringParams = params.map((item) => `&${item}=1`).join('');
    return `${baseUrl}/${path}?${stringParams.slice(1)}`;
  }
  return `${baseUrl}/${path}`;
};
