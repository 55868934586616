import { backgrounds } from 'App';
import EnterEmail from './enterEmail';
import Progress from './progress';
import LeaksList from './leaksList';
import ProtectData from './protectData';
import PaymentPage from './paymentPage';
import Quiz from './quiz';
import Fin from './fin';
import { getStepNameByIndex, STEP_NAMES } from 'steps';
import './index.css';

export const makeId = function (length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter++ < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Steps = ({ leaksData, setLeaksData, email, setEmail, formStep, incrementStep }) => {
  const currentStepName = getStepNameByIndex(formStep);

  const getCurrentStepComponent = () => {
    switch (currentStepName) {
      case STEP_NAMES.ENTER_EMAIL:
        return <EnterEmail setEmail={setEmail} email={email} incrementStep={incrementStep} />;
      case STEP_NAMES.PROGRESS:
        return <Progress incrementStep={incrementStep} email={email} setLeaksData={setLeaksData} />;
      case STEP_NAMES.LEAKS_LIST:
        return <LeaksList leaksData={leaksData} incrementStep={incrementStep} />;
      case STEP_NAMES.QUIZ:
        return <Quiz incrementStep={incrementStep} />;
      case STEP_NAMES.GO_TO_PAY:
        return <ProtectData incrementStep={incrementStep} />;
      case STEP_NAMES.PAYMENT:
        return <PaymentPage email={email} incrementStep={incrementStep} />;
      case STEP_NAMES.SUCCESS:
        return <Fin />;
      default:
        return null;
    }
  };

  return (
    <div
      className="form"
      style={{
        backgroundColor: backgrounds[formStep],
      }}
    >
      <div className="form-content">{getCurrentStepComponent()}</div>
    </div>
  );
};

export default Steps;
