const getCookieValue = (name) => {
  // console.log(name);
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null;
};

// https://binomasia.com/click?cnv_id={clickid} - ввод имейла и нажатие continue
// https://binomasia.com/click?cnv_id={clickid}&event1=1 - открытие страницы с результатами сканирования
// https://binomasia.com/click?cnv_id={clickid}&event2=1 - открытие страницы оплаты
// https://binomasia.com/click?cnv_id={clickid}&event3=1 - успешная оплата (edited)

export const eventParams = {
  EMAIL_ENTERED: '',
  OPEN_SCAN: '&event1=1',
  OPEN_PAYMENT: '&event2=1',
  PAYMENT_SUCCESS: '&event3=1',
};

export const sendPostBack = async (eventParam = eventParams.EMAIL_ENTERED, clickId) => {
  try {
    let img1 = document.createElement('img');
    img1.src = `https://binomasia.com/click?cnv_id=${clickId}${eventParam}`;
    img1.style.cssText = `width:0; height:0; position:absolute; z-index:-5; top: 0; left:0; opacity:0`;
    document.body.appendChild(img1);
  } catch (error) {
    console.log(error);
  }
};

export default getCookieValue;
