import { useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoIc from './assets/logo.svg';
import Steps from './components/steps';
import TopBar from './components/steps/paymentPage/components/top-bar';
import { getStepNameByIndex, STEP_NAMES } from './steps';
import { shouldModifyPage, getUrlParamByName } from 'utils';
import './App.css';

const Logo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #465f93;
`;

export const backgrounds = ['#fff', '#fff', '#F5F6FF', '#F5F6FF', '#F5F6FF', '#f9f9f9', '#F5F6FF'];

function App() {
  const [email, setEmail] = useState('');
  const [formStep, setFormStep] = useState(0);
  const [leaksData, setLeaksData] = useState(null);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    const clickIdParam = getUrlParamByName('clickId');

    if (clickIdParam) {
      document.cookie = `clickid=${clickIdParam}`;
    }

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  useEffect(() => {
    if (shouldModifyPage(getStepNameByIndex(formStep))) {
      setFormStep((current) => current + 1);
      return;
    }

    window.scrollTo(0, 0);
  }, [formStep]);

  const isPaymentStep = getStepNameByIndex(formStep) === STEP_NAMES.PAYMENT;

  return (
    <div
      className="App"
      style={{
        backgroundColor: backgrounds[formStep],
      }}
    >
      {/* <div
            style={{
              position: "fixed",
              bottom: 10,
              left: 10,
              zIndex: 1000000,
            }}
          >
            <button onClick={() => setFormStep((p) => p - 1)}>-1</button>
            <button onClick={() => setFormStep((p) => p + 1)}>+1</button>
          </div> */}
      <div
        className="header"
        style={{
          background: backgrounds[formStep],
        }}
      >
        {isPaymentStep && <TopBar />}
        <div className="header-content">
          <Logo>
            <img src={LogoIc} alt="logo" />
            <p>GuardPRO</p>
          </Logo>
        </div>
      </div>

      <Steps
        email={email}
        setEmail={setEmail}
        formStep={formStep}
        incrementStep={() => setFormStep((step) => step + 1)}
        leaksData={leaksData}
        setLeaksData={setLeaksData}
      />
    </div>
  );
}

export default App;
