import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import styled from 'styled-components';
import { Loader } from 'components/loader';
import Timer from '../components/timer';
import { URL_PATHS } from 'constants';
import { getUrl, isUrlParam } from 'utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #201e1e;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 1.25rem;
  line-height: 1.33;
`;

const Card = styled.div`
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgba(7, 71, 156, 0.25) 0px 11px 11px -9px;
  cursor: pointer;
  border: 2px solid rgb(47, 128, 237);
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    font-weight: 300;
  }
`;

const Mark = styled.div`
  font-weight: 700;
  color: #2f80ed;
  background-color: rgb(213, 230, 251);
  padding: 4px 8px;
  font-size: 0.8rem;
  border-radius: 4px;
`;
const CardPrice = styled.div`
  font-size: 24px;
  margin-top: 8px;
  font-weight: bold;
  color: #201e1e;
`;

const CardBottom = styled.div`
  font-size: 0.87rem;
  font-weight: 300;
  margin-top: 8px;
`;

const CardSwitch = styled.div`
  margin-top: 24px;
  margin-bottom: 36px;
  text-align: center;
  cursor: pointer;
  color: #2f80ed;
  font-size: 1rem;
  font-weight: 600;
`;

const PaymentInfo = styled.div`
  padding: 24px;
  border-radius: 4px;
  background: #eeeeee;
`;

const PaymentTitle = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  font-size: 1rem;
`;

const PaymentPrice = styled.div`
  padding-top: 16px;
  font-size: 1rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 20px 0;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
`;

const Head = ({ isAnnual, setIsAnnual, name, prices, email, incrementStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();
  const [visibility, setVisibility] = useState('hidden');
  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(true);

  const onReady = ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods) {
      // No buttons will show
      setIsPaymentMethodsLoading(false);
    } else {
      // Optional: Animate in the Element
      setVisibility('initial');
    }
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    // Create a PaymentMethod using the details collected by the Express Checkout Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: name,
        },
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    }

    const isTest = isUrlParam('test');
    const res = await fetch(getUrl(URL_PATHS.CREATE_CUSTOMER_AND_SUBSCRIPTION, isTest && ['test']), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: isAnnual ? prices.yearly : prices.monthly,
        onetimePriceId: prices.onetime,
        email,
        test: isTest,
      }),
    });
    const { type, clientSecret } = await res.json();

    const confirmIntent = type === 'setup' ? stripe.confirmCardSetup : stripe.confirmCardPayment;

    const { error: subError /* setupIntent */ } = await confirmIntent(clientSecret, {
      payment_method: paymentMethod.id,
    });

    if (subError) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to your customer (for example, "payment details incomplete").
      // handleError(error);
      setErrorMessage(subError.message);
    } else {
      incrementStep();
    }
  };

  return (
    <Container>
      <Title>Your Billing Cycle</Title>
      <Card>
        <CardHeader>
          <p>{isAnnual ? 'Annual' : 'Monthly'}</p>
          <Mark>{isAnnual ? 'SAVE 33%' : 'BEST OFFER'}</Mark>
        </CardHeader>
        <CardPrice>{isAnnual ? '$9.99 / Monthly' : '$1 Today'}</CardPrice>
        <CardBottom>
          {isAnnual ? (
            <>
              Billed as one payment of{' '}
              <span
                style={{
                  textDecorationLine: 'line-through',
                  textDecorationColor: '#EB5757',
                }}
              >
                $179.88
              </span>{' '}
              <span
                style={{
                  color: '#2F80ED',
                  fontWeight: '700',
                }}
              >
                $119.88
              </span>
            </>
          ) : (
            'After trial $14.99/month'
          )}
        </CardBottom>
      </Card>
      <CardSwitch
        onClick={() => {
          setIsAnnual((p) => !p);
        }}
      >
        {isAnnual ? ' Switch To Monthly For $14.99' : 'Switch To Yearly For $9.99 A Month'}
      </CardSwitch>
      <Title>Add Payment Info</Title>
      <PaymentInfo>
        <div>
          <PaymentTitle>
            <p>Total after 7-day trial</p>
            <p>{isAnnual ? '$119.88/yr' : '$14.99/mo'}</p>
          </PaymentTitle>

          <PaymentPrice style={{ marginBottom: '16px' }}>
            <h3>Due today</h3>
            <h3>$1.00</h3>
          </PaymentPrice>
          <Timer delayMinutes={15} />
        </div>
      </PaymentInfo>
      <div
        style={{
          marginTop: '32px',
          minHeight: '44px',
        }}
      >
        {visibility === 'hidden' && isPaymentMethodsLoading && <Loader width="24px" />}
        <div style={{ visibility }}>
          <ExpressCheckoutElement
            onReady={onReady}
            onConfirm={onConfirm}
            options={{
              layout: {
                maxColumns: 2,
                maxRows: 1,
              },
              buttonType: {
                googlePay: 'plain',
              },
              paymentMethodOrder: ['applePay', 'googlePay', 'link'],
              wallets: {
                applePay: 'always',
                googlePay: 'auto',
              },
            }}
          />
        </div>
      </div>
      <p
        style={{
          marginTop: '8px',
          minHeight: '1rem',
        }}
      >
        {errorMessage && <span>{errorMessage}</span>}
      </p>

      <Footer>
        <Line />
        <p>Continue with credit card</p>
        <Line />
      </Footer>
    </Container>
  );
};

export default Head;
