import { useState } from 'react';
import styled from 'styled-components';
import Carousel from './carousel';

const Container = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;
  width: 100%;
  color: #201e1e;
`;

const Stars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-bottom: 48px;

  & p {
    font-size: 0.87rem;
    font-weight: 300;
    color: #201e1e;
  }
`;

const StarsRating = styled.div``;

const ReviewContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const RoundBtn = styled.div`
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 28px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;

  & svg {
    width: 16px;
    height: 16px;
    transform: rotate(${({ reverse }) => (reverse ? '180deg' : '0')});
  }
`;

const reviews = [
  {
    title: 'The price is amazing',
    text: 'I am very satisfied with Guardpro, thanks to it I found out which resources disclosed my email details. I changed all my passwords and now I feel safe! Thank you',
    name: 'Alex',
    rating: 5,
  },
  {
    title: 'User-friendly and affordable',
    text: 'GuardPRO has done an excellent job of making this product user-friendly and affordable',
    name: 'Mrs. JB',
    rating: 5,
  },
  {
    title: 'Great affordable price!',
    text: "Catches everything that I don't! Great affordable price and they are always easy to work with. Customer service is outstanding.",
    name: 'Daniel',
    rating: 5,
  },
  {
    title: 'Protects you without breaking the bank',
    text: "Finally, a company that protects your internet without breaking the bank. It's affordable for those on a retirement income.",
    name: 'Cindy',
    rating: 5,
  },
  {
    title: 'So cheap compared to the others!!',
    text: 'I love it!!! I was skeptical at first, because of how cheap it was. But, honestly I can see why people love it so much, so cheap compared to the others.',
    name: 'Jonalyn',
    rating: 5,
  },
];

const Reviews = () => {
  const [currentReviewId, setCurrentReviewId] = useState(0);
  const [isAnimStarted, setIsAnimStarted] = useState(false);
  const [direction, setDirection] = useState('left');

  const handleLeft = () => {
    setDirection('left');
    setIsAnimStarted(true);
  };

  const handleRight = () => {
    setDirection('right');
    setIsAnimStarted(true);
  };

  return (
    <Container>
      <Stars>
        <StarsRating>
          <StarFull />
          <StarFull />
          <StarFull />
          <StarFull />
          <StarHalf />
        </StarsRating>
        <p>4.6/5 based on 1000+ Trustpilot reviews</p>
      </Stars>

      <ReviewContainer>
        <RoundBtn onClick={() => handleLeft()}>
          <ArrowSvg />
        </RoundBtn>
        <Carousel
          reviews={reviews}
          currentReviewId={currentReviewId}
          setCurrentReviewId={setCurrentReviewId}
          isAnimStarted={isAnimStarted}
          setIsAnimStarted={setIsAnimStarted}
          direction={direction}
        />

        <RoundBtn reverse={true} onClick={() => handleRight()}>
          <ArrowSvg />
        </RoundBtn>
      </ReviewContainer>
    </Container>
  );
};

export default Reviews;

const StarFull = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.4127 1L15.7983 8.34006L23.8254 9.2918L17.8908 14.7799L19.4661 22.7082L12.4127 18.76L5.35925 22.7082L6.93459 14.7799L1 9.2918L9.02703 8.34006L12.4127 1Z"
      fill="#FFB400"
    />
  </svg>
);

const StarHalf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.4127 1L15.7983 8.34006L23.8254 9.2918L17.8908 14.7799L19.4661 22.7082L12.4127 18.76L5.35925 22.7082L6.93459 14.7799L1 9.2918L9.02703 8.34006L12.4127 1Z"
      fill="#FFB400"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4135 1L15.7991 8.34006L23.8262 9.2918L17.8916 14.7799L19.4669 22.7082L12.4135 18.76V1Z"
      fill="#B9B9B9"
    />
  </svg>
);

const ArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.5 1L4.39424 9.62651C4.18197 9.82761 4.18681 10.1671 4.40471 10.3621L13.5 18.5"
      stroke="#868686"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);
