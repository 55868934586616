import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { eventParams, sendPostBack } from 'helpers';
import Benefits from './components/benefits';
import Faq from './components/faq';
import PaymentForm from './components/paymentForm';
import Reviews from './components/reviews';
import Loader from 'components/loader';
import { URL_PATHS } from 'constants';
import { isUrlParam, getUrl } from 'utils';

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30%;
  height: fit-content;
  padding-top: 43px;

  @media (min-width: 900px) {
    padding-bottom: 0;
    border-radius: 12px;
  }
`;

const PaymentPage = memo(({ email, clientSecretMonth, incrementStep }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [prices, setPrices] = useState(null);

  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {
    const url = getUrl(URL_PATHS.CONFIG, isUrlParam('test') && ['test']);

    fetch(url).then(async (r) => {
      const { publishableKey, prices } = await r.json();

      setPrices(prices);
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    const clickId = isUrlParam('clickId');

    if (clickId) {
      sendPostBack(eventParams.OPEN_PAYMENT, clickId);
    }
  }, []);

  const options = {
    mode: 'subscription',
    amount: 100,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    theme: 'stripe',
  };

  return (
    <Container>
      {!stripePromise && <Loader isFixed={true} />}
      <Elements stripe={stripePromise} options={options} key={1}>
        <PaymentForm
          isAnnual={isAnnual}
          setIsAnnual={setIsAnnual}
          secret={clientSecretMonth}
          incrementStep={incrementStep}
          email={email}
          prices={prices}
        />
      </Elements>
      <Reviews />
      <Benefits />
      <Faq />
    </Container>
  );
});
export default PaymentPage;
