const questionnaire = [
  {
    id: '1',
    title: 'How often do you change your passwords?',
    options: [
      {
        id: '3-6 months',
        text: 'Every 3-6 months',
      },
      {
        id: 'Once a year',
        text: 'Once a year',
      },
      {
        id: 'Occasionally',
        text: 'Only when I learn about a data breach',
      },
    ],
  },
  {
    id: '2',
    title: 'How do you usually find out about data breaches?',
    options: [
      {
        id: 'From the news',
        text: 'From the news',
      },
      {
        id: 'When I receive a notification from the affected service',
        text: 'When I receive a notification from the affected service',
      },
      {
        id: "I don't find out about breaches until I run into problems",
        text: "I don't find out about breaches until I run into problems",
      },
    ],
  },
  {
    id: '3',
    title: 'Do you use unique passwords for different sites?',
    options: [
      {
        id: 'Yes, a unique one for each site',
        text: 'Yes, a unique one for each site',
      },
      {
        id: 'I use several strong passwords',
        text: "I use several 'strong' passwords",
      },
      {
        id: 'Usually, I use the same password everywhere',
        text: 'Usually, I use the same password everywhere',
      },
    ],
  },
  {
    id: '4',
    title: 'How do you store your passwords?',
    options: [
      {
        id: 'In a password manager',
        text: 'In a password manager',
      },
      {
        id: 'On paper/in a text file',
        text: 'On paper/in a text file',
      },
      {
        id: 'Memorize them',
        text: 'Memorize them',
      },
    ],
  },
  {
    id: '5',
    title: 'What do you consider most important when choosing a service to protect against data breaches?',
    options: [
      {
        id: 'Regular notifications about new breaches',
        text: 'Regular notifications about new breaches',
      },
      {
        id: 'Ease of use',
        text: 'Ease of use',
      },
      {
        id: 'Subscription cost',
        text: 'Subscription cost',
      },
    ],
  },
  {
    id: '6',
    title: 'How often would you like to receive reports on the security status of your data?',
    options: [
      {
        id: 'Weekly',
        text: 'Weekly',
      },
      {
        id: 'Monthly',
        text: 'Monthly',
      },
      {
        id: 'Upon detecting a new leak',
        text: 'Upon detecting a new leak',
      },
    ],
  },
  {
    id: '7',
    title: 'What precautions do you prefer to take to protect your data online?',
    options: [
      {
        id: 'Use two-factor authentication',
        text: 'Use two-factor authentication',
      },
      {
        id: 'Regularly check for data breaches',
        text: 'Regularly check for data breaches',
      },
      {
        id: 'Limit the amount of personal information I share online',
        text: 'Limit the amount of personal information I share online',
      },
    ],
  },
  {
    id: '8',
    title: 'Which notifications would you like to subscribe to?',
    options: [
      {
        id: 'Notifications of new data breaches',
        text: 'Notifications of new data breaches',
      },
      {
        id: 'Tips for improving security',
        text: 'Tips for improving security',
      },
      {
        id: 'News about cybersecurity',
        text: 'News about cybersecurity',
      },
    ],
  },
  {
    id: '9',
    title: 'How would you prefer to receive notifications from our service?',
    options: [
      {
        id: 'By email',
        text: 'By email',
      },
      {
        id: 'Through a mobile app',
        text: 'Through a mobile app',
      },
      {
        id: 'SMS',
        text: 'SMS',
      },
    ],
  },
];
export default questionnaire;
