import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isUrlParam, shouldModifyPage, getUrl } from 'utils';
import Head from '../../head';
import { StripeIc } from '../icon';
import { Button } from 'components/ui';
import Loader from 'components/loader';
import { URL_PATHS } from 'constants';
import { STEP_NAMES } from 'steps';

const BtnContainer = styled.div`
  bottom: 0;
  margin: 0px -20px;
  padding: 20px;
  z-index: 1;
  position: sticky;
  background: #f9f9f9;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  margin-top: 12px;
`;
const SubmitBtn = styled(Button)`
  border: none;
  color: ${({ isErr }) => (isErr ? '#c9c9c9' : '#ffffff')};
  background: ${({ isErr }) => (isErr ? '#DBDBDB' : '#2F79E8')};
`;

const FormContainer = styled.form`
  width: 100%;
  @media (max-width: 900px) {
    padding: 20px 24px;
  }
`;

const FormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  & p {
    font-size: 0.75rem;
    font-family: 'sofia-pro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    line-height: 1.66;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
  }
`;

const CardCustomInput = styled.div`
  margin: 0;
  padding: 14px 12px;
  background-color: #fff;
  border-radius: 4px;
  border: ${({ err }) => (err ? '1px solid #f44336' : '1px solid #ced4da')};

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NameInput = styled.input`
  margin: 0;
  outline: none;
  padding: 14px 12px;
  background-color: #fff;
  border: ${({ isError }) => (isError ? '1px solid #f44336' : '1px solid #ced4da')};
  border-radius: 4px;
  min-height: 48px;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
`;

const Law = styled.div`
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.75rem;
  padding: 20px;

  & a {
    color: rgba(0, 0, 0, 0.38);
  }
`;

const monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const PaymentForm = memo(({ email, isAnnual, setIsAnnual, incrementStep, prices }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState(null);
  const [isFName, setIsFName] = useState(null);
  const [isSName, setIsSName] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [values, setValues] = useState({
    fName: '',
    sName: '',
  });

  const ref = useRef();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const handleScroll = () => {
      const refTop = ref.current?.getBoundingClientRect().top;

      if (refTop < 200) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const card = await elements.getElement('card');
    const { error /* token */ } = await stripe.createToken(card);

    debugger;

    if (error) {
      setMessage(error.message);
      setTimeout(() => {
        setIsProcessing(false);
      }, 200);

      return;
    }
    const isTest = isUrlParam('test');

    // Create the subscription
    const res = await fetch(`${getUrl(URL_PATHS.CREATE_CUSTOMER_AND_SUBSCRIPTION, isTest && ['test'])}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: isAnnual ? prices.yearly : prices.monthly,
        onetimePriceId: prices.onetime,
        email,
        test: isTest,
      }),
    });

    const { type, clientSecret } = await res.json();
    const confirmIntent = type === 'setup' ? stripe.confirmCardSetup : stripe.confirmCardPayment;

    const { error: subError /* setupIntent */ } = await confirmIntent(clientSecret, {
      payment_method: {
        card: elements.getElement('card'),
        billing_details: {
          name: `${values.fName} ${values.sName}`,
        },
      },
    });

    if (subError) {
      setMessage(subError.message);
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      incrementStep();
    }
  };

  const handleYear = () => {
    const now = Date.now();
    const end = now + 32140800000;
    const date = `${new Date(end).getDate()} ${monthes[new Date(end).getMonth()]} ${new Date(end).getFullYear()}`;

    return date;
  };

  const handleMonth = () => {
    const now = Date.now();
    const end = now + 3283200000;
    const date = `${new Date(end).getDate()} ${monthes[new Date(end).getMonth()]} ${new Date(end).getFullYear()}`;

    return date;
  };

  return (
    <>
      <FormContainer id="payment-form">
        <Head
          isAnnual={isAnnual}
          setIsAnnual={setIsAnnual}
          prices={prices}
          email={email}
          name={`${values.fName} ${values.sName}`}
          incrementStep={incrementStep}
        />
        <FormTitle>
          <p>Billing information</p>
          <StripeIc />
        </FormTitle>
        <InputsContainer ref={ref}>
          <NameInput
            value={values.fName}
            placeholder="Your First name"
            onInput={() => {
              setIsFName(null);
            }}
            onChange={(e) => {
              setValues((p) => ({
                ...p,
                fName: e.target.value,
              }));
            }}
            isError={isFName}
          />
          <NameInput
            value={values.sName}
            placeholder="Your Last name"
            onInput={() => {
              setIsSName(null);
            }}
            onChange={(e) => {
              setValues((p) => ({
                ...p,
                sName: e.target.value,
              }));
            }}
            isError={isSName}
          />
          <div>
            <CardCustomInput err={message}>
              <CardElement
                onChange={() => {
                  setMessage(null);
                }}
                /* onReady={onReady} */
              />
            </CardCustomInput>

            <p
              style={{
                color: '#f44336',
                fontSize: '0.8rem',
                minHeight: '1rem',
                marginTop: '8px',
              }}
            >
              {message && <span>{message}</span>}
              {isFName && <span>{isFName}</span>}
              {isSName && <span>{isSName}</span>}
            </p>
          </div>
        </InputsContainer>

        <BtnContainer>
          <SubmitBtn
            id="submit-btn"
            type="submit"
            disabled={message}
            isErr={message}
            onClick={(e) => {
              e.preventDefault();

              window.scrollTo({
                top: ref.current.getBoundingClientRect().top - 200,
              });

              if (values.fName?.length <= 0 || !values.fName) {
                return setIsFName('please fill in name');
              }
              if (values.sName?.length <= 0 || !values.sName) {
                return setIsSName('please fill in second name');
              }
              handleSubmit(e);
            }}
          >
            <span>{isFocused ? 'Pay $1 Today' : 'Start My Trial'}</span>
          </SubmitBtn>
        </BtnContainer>
      </FormContainer>
      <Law>
        {isAnnual ? (
          <>
            Starting on {handleYear()} your subscription will automatically renew yearly. You will be charged
            USD&nbsp;119.88 on each renewal until you cancel in accordance with our Terms of Use . If you cancel, you
            may continue to use the service until the end of the term you paid for. An authorization hold will be placed
            on your card when you start your trial. This is only to check your card is valid, and you won’t be charged
            until your trial ends. By clicking the button above, you agree to our{' '}
            <a href="https://guardproapp.com/terms/">Terms of Service</a> and{' '}
            <a href="https://guardproapp.com/privacy/">Privacy Policy</a>
          </>
        ) : (
          <>
            Starting on {handleMonth()} your subscription will automatically renew monthly. You will be charged
            USD&nbsp;14.99 on each renewal until you cancel in accordance with our Terms of Use
            <a href="https://guardproapp.com/terms/">Terms of Service</a>. If you cancel, you may continue to use the
            service until the end of the term you paid for. An authorization hold will be placed on your card when you
            start your trial. This is only to check your card is valid, and you won’t be charged until your trial ends.
            By clicking the button above, you agree to our <a href="https://guardproapp.com/terms/">Terms of Service</a>{' '}
            and <a href="https://guardproapp.com/privacy/">Privacy Policy</a>
          </>
        )}
      </Law>
      {isProcessing && <Loader text="Processing..." isFixed={true} />}
    </>
  );
});

export default PaymentForm;
