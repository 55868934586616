export const Radar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 70 70" fill="none">
    <circle cx="35" cy="35" r="35" fill="#4A93F3" />
    <path
      d="M70 35C70 42.4527 67.6211 49.7108 63.2096 55.7176C58.7981 61.7244 52.5845 66.1662 45.4733 68.3963C38.3621 70.6264 30.7247 70.5284 23.6731 68.1165C16.6214 65.7046 10.5238 61.1048 6.26796 54.9867C2.01211 48.8687 -0.179747 41.5519 0.0115294 34.1017C0.202806 26.6515 2.76722 19.4568 7.33141 13.5652C11.8956 7.67366 18.2212 3.3928 25.3873 1.34593C32.5534 -0.700933 40.1858 -0.406924 47.1732 2.18515L35 35H70Z"
      fill="url(#paint0_linear_280_12)"
    />
    <circle cx="35" cy="35" r="34.5" stroke="#7CB0F5" />
    <circle cx="35" cy="35" r="24.5" stroke="#76A6E7" />
    <circle cx="35.5" cy="35.5" r="16" stroke="#76A6E6" />
    <defs>
      <linearGradient id="paint0_linear_280_12" x1="16" y1="10.5" x2="33" y2="38" gradientUnits="userSpaceOnUse">
        <stop offset="0.422283" stopColor="#A5CCFF" />
        <stop offset="1" stopColor="#A5CCFF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
