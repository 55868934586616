import { styled } from 'styled-components';
import guardTop from 'assets/guard-top.svg';
import spotTop from 'assets/spot-top.svg';
import spotRight from 'assets/spot-right.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 32px;
  position: relative;
  height: 100%;
  @media (max-width: 900px) {
    padding: 0 24px 32px;
  }
  & .snackbar {
    width: 90%;
    color: #fff;
    display: flex;
    padding: 6px 16px;
    flex-grow: 1;
    flex-wrap: wrap;
    font-size: 0.875rem;
    align-items: center;
    font-family: 'Inter', 'sofia-pro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    line-height: 1.8;
    border-radius: 4px;
    letter-spacing: normal;
    background-color: rgb(49, 49, 49);
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 1rem;
    .MuiAlert-icon {
      color: #fff;
    }
    .MuiAlert-message {
      padding-right: 16px;
    }
    .MuiAlert-action {
      cursor: pointer;
      margin-right: 0;
    }
  }
  .MuiFormHelperText-root {
    color: red !important;
  }

  .top-gap {
    display: none;
  }
  @media (min-width: 900px) {
    .top-gap {
      display: block;
      height: 200px;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 12px;
  color: #172335;
  font-size: 1.11rem;
  line-height: 1.67rem;
  font-weight: 800;
`;

export const Text = styled.div`
  font-size: 0.888rem;
  line-height: 1.35rem;
  font-weight: 500;
  letter-spacing: 0.1px;
`;
export const TextPositioned = styled(Text)`
  margin-bottom: 56px;
`;

export const Button = styled.button`
  width: 100%;
  min-width: 240px;

  padding: 20px 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border: none;
  border-radius: 16px;

  font-size: 1rem;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;

  background: #2f79e8;
  cursor: pointer;
`;

export const ButtonBottomPositioned = styled(Button)`
  @media (max-width: 900px) {
    margin-top: auto;
  }
`;

export const GuardBackground = styled(Container)`
  background-image: url(${guardTop}), url(${spotTop}), url(${spotRight});
  background-position:
    right 0,
    left 204px,
    right 380px;
  background-repeat: no-repeat;
`;
