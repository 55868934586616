import styled from 'styled-components';

const ReviewItem = styled.div`
  width: 100%;
`;

const ReviewTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ReviewText = styled.p`
  font-size: 0.875rem;
  opacity: 0.9;
  min-height: 110px;
  margin-bottom: 10px;
`;

const ReviewAuthor = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ReviewRating = styled.div`
  & p {
    font-size: 0.875rem;
    opacity: 0.9;
  }
  & div {
    display: flex;
    gap: 1px;
    & svg {
      width: 16px;
    }
  }
`;

const Item = ({ review }) => {
  const handleReviewStars = (count) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(i);
    }
    return arr;
  };

  return (
    <ReviewItem>
      <ReviewTitle>{review.title}</ReviewTitle>
      <ReviewText>{review.text}</ReviewText>
      <ReviewAuthor>{review.name}</ReviewAuthor>
      <ReviewRating>
        <p>Trustpilot</p>
        <div>
          {handleReviewStars(review.rating).map((e, i) => (
            <StarFull key={i} />
          ))}
        </div>
      </ReviewRating>
    </ReviewItem>
  );
};

export default Item;

const StarFull = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.4127 1L15.7983 8.34006L23.8254 9.2918L17.8908 14.7799L19.4661 22.7082L12.4127 18.76L5.35925 22.7082L6.93459 14.7799L1 9.2918L9.02703 8.34006L12.4127 1Z"
      fill="#FFB400"
    />
  </svg>
);
